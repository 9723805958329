import { useApolloClient, useQuery } from "@apollo/client";
import { Mutex } from "async-mutex";
import { isObject } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useGetSet } from "react-use";
import { IndexRange } from "react-virtualized";

import {
  buildCountQuery,
  buildFetchQuery,
  placeholderVariables,
} from "~src/shared/lists/gql/query";
import { IListConfig, IListRow, IListRowData } from "~src/shared/lists/types";
import { IListDataSource } from "~src/shared/lists/types/data";
import { IListModel } from "~src/shared/lists/types/models";
import { configKey } from "~src/shared/lists/utils/config";

type Cache<M extends IListModel> = {
  key: string;
  byIndex: Record<number, IListRow<M>>;
  byID: Record<string, number>; // references rows in the index cache.
};

export const useListData = <M extends IListModel>(config: IListConfig<M>): IListDataSource<M> => {
  const key = useMemo(() => configKey(config), [config]);

  const apollo = useApolloClient();
  // NOTE(johnrjj) - These eslint-disables are overridden because we use the config key to trigger updates
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const placeholders = useMemo(() => placeholderVariables(config), [key]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mutex = useMemo(() => new Mutex(), [config.model]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const countQuery = useMemo(() => buildCountQuery(config), [key]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchQuery = useMemo(() => buildFetchQuery(config), [key]);
  const { data, loading, refetch } = useQuery(countQuery, {
    variables: placeholders,
  });
  const count = (data ?? {})[`${config.model}_aggregate`]?.aggregate?.count;

  const [getCache, setCache] = useGetSet<Cache<M>>({ key, byIndex: {}, byID: {} });

  // If the list changes, invalidate the row cache and force a refetch.
  useEffect(() => {
    setCache({ key, byIndex: {}, byID: {} });
  }, [setCache, key]);

  const getRowByIndex = useCallback(
    (index: number) => {
      return getCache().byIndex[index] ?? null;
    },
    [getCache],
  );

  const getRowByID = useCallback(
    (publicID: string) => {
      const cache = getCache();
      const index = cache.byID[publicID];
      if (index === undefined) {
        return null;
      }
      return cache.byIndex[index] ?? null;
    },
    [getCache],
  );

  const loadRows = useCallback(
    async (range: IndexRange): Promise<void> => {
      const offset = range.startIndex;
      const limit = range.stopIndex - range.startIndex + 1; // stopIndex is inclusive

      if (limit <= 0) {
        return;
      }

      if (count === undefined || count === 0) {
        return;
      }

      let cache = getCache();
      // We can probably make this optimization a lot better, but if we already have the data loaded
      // for the entire list, exit early because there's nothing to fetch.
      if (Object.keys(cache.byIndex).length === count) {
        return;
      }

      let result = null;
      const cachedKey = cache.key;
      const release = await mutex.acquire();
      try {
        result = await apollo.query({
          query: fetchQuery,
          variables: {
            offset,
            limit,
            ...placeholders,
          },
        });
      } finally {
        release();
      }

      // If the config changed while the request was in flight, we should ignore the results since they are
      // stale.
      //
      // TODO(usmanm): Figure out a way to abort requests. This is especially important because we only allow
      // a single request at a time. So if someone clicks sort multiple times, it'll wait a while to process
      // the stale requests before we reach the final copy.
      // See: https://dev.to/viclafouch/cancel-properly-http-requests-in-react-hooks-and-avoid-memory-leaks-pd7
      cache = getCache();
      if (cache.key !== cachedKey) {
        return;
      }

      const rows: IListRow<M>[] = result.data[`${config.model}`].map(
        (m: Record<string, unknown>, idx: number) => ({
          index: idx + offset,
          id: m.public_id as string,
          data: flatten(m) as IListRowData<M>,
        }),
      );

      const byID = { ...cache.byID };
      const byIndex = { ...cache.byIndex };
      rows.forEach((row, idx) => {
        byIndex[range.startIndex + idx] = row;
        byID[row.id] = range.startIndex + idx;
      });
      setCache({ ...cache, byIndex, byID });
    },
    [count, getCache, mutex, config.model, setCache, apollo, fetchQuery, placeholders],
  );

  const loadAllRows = useCallback(async () => {
    if (count == null) {
      return;
    }
    await loadRows({
      startIndex: 0,
      stopIndex: count,
    });
  }, [count, loadRows]);
  const getAllRows = useCallback(() => Object.values(getCache().byIndex), [getCache]);

  const reset = useCallback(async () => {
    await refetch(placeholders);
    setCache({ key, byIndex: {}, byID: {} });
  }, [refetch, placeholders, setCache, key]);

  const setRowDataByID = useCallback(
    (publicID: string, rowData: IListRowData<M>) => {
      const row = getRowByID(publicID);
      if (row === null) {
        return;
      }
      const cache = getCache();
      setCache({
        ...cache,
        byIndex: { ...cache.byIndex, [row.index]: { ...row, data: rowData } },
      });
    },
    [getRowByID, getCache, setCache],
  );

  const setRowDataByIndex = useCallback(
    (index: number, rowData: IListRowData<M>) => {
      const row = getRowByIndex(index);
      if (row === null) {
        return;
      }
      const cache = getCache();
      setCache({
        ...cache,
        byIndex: { ...cache.byIndex, [row.index]: { ...row, data: rowData } },
      });
    },
    [getRowByIndex, getCache, setCache],
  );

  return {
    config,
    loading,
    count,
    loadRows,
    loadAllRows,
    reset,
    getAllRows,
    getRowByIndex,
    getRowByID,
    setRowDataByID,
    setRowDataByIndex,
  };
};

// Flattens object up to 1-level deep.
// TODO(usmanm): this will break for JSON objects, so fix accordingly.
const flatten = (r: Record<string, unknown>): Record<string, unknown> => {
  const fixed: Record<string, unknown> = {};
  const nested: Record<string, unknown>[] = [];

  Object.keys(r).forEach((k) => {
    if (isObject(r[k])) {
      nested.push(r[k] as Record<string, unknown>);
    } else {
      fixed[k] = r[k];
    }
  });

  return Object.assign(fixed, ...nested);
};
